import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faEye, faSync, faEdit } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';
const Swal = require('sweetalert2');

library.add(faFilter, faEye, faSync, faEdit);

const TaxCredentials = () => {
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [responsibleMembers, setResponsibleMembers] = useState({});
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState('');
const [showAmountInput, setShowAmountInput] = useState(false);

  const [selectedColumns, setSelectedColumns] = useState({
    CC: true,
    TradeName: true,
    PAN: true,
    ITID: true,
    ITPass: true,
    TAN: true,
    TracesID: true,
    TracesPass: true,
    ITLastEdited:false,
    TracesLastEdited:false,
    levelOfBOA:true,
    reportingFrequency:true,
    itar:true,
    Priority:true,
    ResponsibleMember:true,
    itChallan:true,
    itChallanStatus:true,
    itfreq:true

  });
  const [refresh, setRefresh] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [visibleFields, setVisibleFields] = useState({});


  const email = sessionStorage.getItem('email');
  const FullName = requests[0]?.FirstName +' '+ requests[0]?.LastName
  
  useEffect(() => {
    const fetchUserClientRequests = async () => {
      setLoading(true);
      try {
        const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/usermyclientdetails', { email });
        const requestsData = response.data;
        setRequests(requestsData);
        setFilteredRequests(requestsData);
        
        // Fetch responsible members for each request's CC
        const memberRequests = requestsData.map(request => {
          return axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/fetchresponsibemember', { CC: request.CC });
        });
  
        const members = await Promise.all(memberRequests);
        const responsibleMembersData = members.reduce((acc, memberResponse, index) => {
          acc[requestsData[index].CC] = memberResponse.data.responsibleMember;
          return acc;
        }, {});
  
        setResponsibleMembers(responsibleMembersData);
      } catch (error) {
        console.error('Error fetching client details or responsible members:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchUserClientRequests();
  }, [email, refresh]);
  useEffect(() => {
    const filtered = requests.filter((request) =>
      Object.values(request).some(
        (value) =>
          value !== null &&
          value !== undefined &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredRequests(filtered);
  }, [searchTerm, requests]);

  const handleColumnChange = (e) => {
    setSelectedColumns({
      ...selectedColumns,
      [e.target.name]: e.target.checked,
    });
  };

  const handleRefresh = () => {
    setRefresh((prevRefresh) => !prevRefresh);
  };



  const taxLogin = () => {
    window.open('https://eportal.incometax.gov.in/iec/foservices/#/login' , '_blank', 'noopener,noreferrer,width=1800,height=1800');
    };
    const tracesLogin = () => {
      window.open('https://www.tdscpc.gov.in/app/login.xhtml?usr=Ded', '_blank', 'noopener,noreferrer,width=1800,height=1800');
      };

  // const toggleVisibility = (index, field) => {
  //   setVisibleFields((prev) => ({
  //     ...prev,
  //     [field]: !prev[field],
  //   }));
  // };

  
  const toggleVisibility = (requestCC) => {
    setVisibleFields((prevVisibleRequests) => ({
      ...prevVisibleRequests,
      [requestCC]: !prevVisibleRequests[requestCC],
    }));
  };

  const handleSelectAll = () => {
    const newSelectedColumns = Object.keys(selectedColumns).reduce((acc, column) => {
      acc[column] = true; // Set all columns to true
      return acc;
    }, {});
    setSelectedColumns(newSelectedColumns);
  };
  
  const handleSelectNone = () => {
    const newSelectedColumns = Object.keys(selectedColumns).reduce((acc, column) => {
      acc[column] = false; // Set all columns to false
      return acc;
    }, {});
    setSelectedColumns(newSelectedColumns);
  };
  const handleEdit = (request, fieldType) => {
    const fieldPass = `${fieldType}Pass`;
    const fieldID = `${fieldType}ID`;
    
    Swal.fire({
      title: `Edit ${fieldType} Credentials`,
      html: `
        <div class="mb-3">
          <label class="form-label float-start">Email</label>
          <input type="text" class="form-control" value="${request.empid[0]}" disabled>
        </div>
        <div class="mb-3">
          <label class="form-label float-start">${fieldType} ID</label>
          <input type="text" class="form-control" id="edit-id" value="${request[fieldID]}" disabled>
        </div>
        <div class="mb-3">
          <label class="form-label float-start">Current ${fieldType} Password</label>
          <input type="text" class="form-control"  id="old-password" value="${request[fieldPass]}" disabled>
        </div>
        <div class="mb-3">
          <label class="form-label float-start">Set New ${fieldType} Password</label>
          <input type="text" id="new-password" class="form-control" placeholder="Enter new password">
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: 'Update',
      confirmButtonColor: '#198754',
      preConfirm: () => {
        const id = document.getElementById('edit-id').value;
        const newPassword = document.getElementById('new-password').value;
        const oldPassword = document.getElementById('old-password').value;
        if (!newPassword) {
          Swal.showValidationMessage('New password is required');
        }

        return { newPassword ,oldPassword,id};
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { id,newPassword ,oldPassword} = result.value;

        axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updatetaxcredentials', {
          email,
          empid: requests[0].empid[0],
          FullName:FullName,
          CC: request.CC,
          newPassword,
          oldPassword,
          type:fieldType,
          credentialID:id,
          TradeName:request.TradeName,
        })
          .then(() => {
            Swal.fire({
              toast: true,
              text: `${fieldType} credentials have been updated.`,
              icon: 'success',
              toast: true,
              showConfirmButton: false,
              position: 'top-end',
              timer: 1000,
              timerProgressBar: true,
              willClose: () => {
                handleRefresh();
              }
            });
          })
          .catch((error) => {
            Swal.fire('Error!', 'Failed to update the credentials.', 'error');
            console.error('Error updating credentials:', error);
          });
      }
    });
  };

  return (
    <div className="container mt-5" style={{ maxWidth: '2000px' }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>TAX Compliances</h2>
        <div className="d-flex align-items-center">
        <button className="btn btn-secondary me-2" onClick={taxLogin}>
             IT Login
          </button>
          {/* <button className="btn btn-secondary me-2" onClick={tracesLogin}>
             Traces Login
          </button> */}

{/* 
          <button className="btn btn-secondary me-2" onClick={handleRefresh}>
            <FontAwesomeIcon icon={faSync} /> Refresh
          </button> */}
          <div className="dropdown me-2">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="filterDropdown" data-bs-toggle="dropdown" aria-expanded="false">
              <FontAwesomeIcon icon={faFilter} /> Filter Columns
            </button>
            <ul className="dropdown-menu" aria-labelledby="filterDropdown">
    <li className="dropdown-item">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="selectAll"
          checked={Object.values(selectedColumns).every(Boolean)}
          onChange={handleSelectAll}
        />
        <label className="form-check-label" htmlFor="selectAll">
          Select All
        </label>
      </div>
    </li>
    <li className="dropdown-item">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="selectNone"
          checked={Object.values(selectedColumns).every(value => !value)}
          onChange={handleSelectNone}
        />
        <label className="form-check-label" htmlFor="selectNone">
          Select None
        </label>
      </div>
    </li>
              {Object.keys(selectedColumns).map((column) => (
                <li key={column} className="dropdown-item">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name={column}
                      checked={selectedColumns[column]}
                      onChange={handleColumnChange}
                      id={`checkbox-${column}`}
                    />
                    <label className="form-check-label" htmlFor={`checkbox-${column}`}>
                      {column}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border mt-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : filteredRequests.length === 0 ? (
        <p>No Clients found.</p>
      ) : (
        <table className="table table-striped">
          <thead style={{ fontSize: '15px', verticalAlign: 'baseline' }}>
            <tr>
            {selectedColumns.TradeName && <th>Trade Name <br />GSTIN</th>}
            {/* {selectedColumns.Priority && <th>P</th>} */}
              {/* {selectedColumns.levelOfBOA && <th>L</th>} */}
              {/* {selectedColumns.CC && <th>CC</th>} */}
              {selectedColumns.TradeName && <th>Trade Name <br />GSTIN</th>}
              
              {selectedColumns.PAN && <th>PAN</th>}
              {selectedColumns.ITID && <th>IT ID</th>}
              {selectedColumns.ITPass && <th>IT Pass</th>}
              {selectedColumns.itar && <th>ITAR</th>}

              {selectedColumns.itChallan && <th>ITC</th>}
              {selectedColumns.itChallanStatus && <th>ITC Status</th>}
              {selectedColumns.itfreq && <th>It freq</th>}
          
              {selectedColumns.ITLastEdited && <th>IT Last Edited</th>}

            </tr>
          </thead>
          <tbody style={{ fontSize: '14px', verticalAlign: 'baseline' }}>
            {filteredRequests.map((request, index) => {
const responsibleMember = responsibleMembers[request.CC];

const handleEditITStatus = (request) => {
  Swal.fire({
    title: 'Edit IT Status',
    html: `
      <div class="mb-3">
        <label class="form-label">Select Status</label>
        <select id="status-select" class="form-select">
          <option value="">Select Option</option>
          <option value="Paid">Paid</option>
          <option value="P-client Info">P-client Info</option>
          <option value="P-Challan">P-Challan</option>
          <option value="P-Due Date">P-Due Date</option>
        </select>
      </div>
      <div class="mb-3">
        <label class="form-label">Month</label>
        <input type="month" id="month-input" class="form-control" value="${request.itMonth}" />
      </div>
      <div class="mb-3" id="amount-input-container" style="display: none;">
        <label class="form-label">Amount</label>
        <input type="text" id="amount-input" class="form-control" placeholder="Enter amount" value="${amount}" />
      </div>
    `,
    showCancelButton: true,
    confirmButtonText: 'Save Changes',
    cancelButtonText: 'Cancel',
    allowOutsideClick: false,
    confirmButtonColor: '#198754',
    didOpen: () => {
      const statusSelect = document.getElementById('status-select');
      const amountInputContainer = document.getElementById('amount-input-container');

      // Event listener to show/hide amount input
      statusSelect.addEventListener('change', () => {
        const selectedStatus = statusSelect.value;
        if (selectedStatus === 'Paid') {
          amountInputContainer.style.display = 'block'; // Show amount input
        } else {
          amountInputContainer.style.display = 'none'; // Hide amount input
          setAmount(''); // Reset the amount
        }
      });
    },
  }).then((result) => {
    if (result.isConfirmed) {
      const selectedStatus = document.getElementById('status-select').value;
      const selectedMonth = document.getElementById('month-input').value;
      const enteredAmount = document.getElementById('amount-input').value;

      console.log('Updated Status:', selectedStatus, 'Updated Month:', selectedMonth, 'Amount:', enteredAmount);

      axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateItChallanStatus', {
        email,
        empid: requests[0].empid[0],
        FullName: FullName,
        CC: request.CC,
        itChallanStatus: selectedStatus,
        itMonth: selectedMonth,
        amount: selectedStatus === 'Paid' ? enteredAmount : null, // Send amount only if status is Paid
      })
        .then(() => {
          Swal.fire({
            toast: true,
            text: `credentials have been updated.`,
            icon: 'success',
            position: 'top-end',
            timer: 1000,
            timerProgressBar: true,
            showConfirmButton: false,
            willClose: () => {
              handleRefresh();
            },
          });
      })
      .catch(error => {
        console.error('Error updating IT status:', error);
        Swal.fire('Error!', 'There was a problem saving your changes.', 'error');
      });
    }
  });
};

return (
<tr key={index}>
{selectedColumns.ResponsibleMember && (
  <td>
    {responsibleMember ? (
      <>
        {responsibleMember.firstname}
        <br />
        {request.Priority} {request.levelOfBOA} {request.CC}
      </>
    ) : (
      'N/A'
    )}
  </td>
)}
                 {/* {selectedColumns.Priority && <td>{request.Priority}</td>} */}
                {/* {selectedColumns.levelOfBOA && <td>{request.levelOfBOA}</td>} */}
                {/* {selectedColumns.CC && <td>{request.CC}</td>} */}
                {selectedColumns.TradeName && <td>{request.TradeName} <br />{request.GSTIN}</td>}
                {selectedColumns.PAN && <td>{request.PAN}</td>}
                {selectedColumns.ITID && (
                  <td>
                    {visibleFields[request.CC] ? (
                      <span>{request.ITID}</span>
                    ) : (
                      <span>
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => toggleVisibility(request.CC)}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    )}
                  </td>
                )}
                {selectedColumns.ITPass && (
                  <td>
                    {visibleFields[request.CC] ? (
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        {request.ITPass}
                      
                          <FontAwesomeIcon className='ms-2 text-success cursor-pointer' icon={faEdit} onClick={() => handleEdit(request, 'IT')} style={{ cursor: 'pointer' }}/>
                      </span>
                    ) : (
                      <span>
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => toggleVisibility(request.CC)}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    )}
                  </td>
                )}

                
                {/* {selectedColumns.reportingFrequency && <td>{request.reportingFrequency}</td>} */}
                {selectedColumns.itar && <td>{request.itar}</td>}

                {selectedColumns.itChallan && <td>{request.itChallan}</td>}
                {selectedColumns.itChallanStatus && 
                (
                <td>
                <span>
              {request.itChallanStatus && request.itChallanStatus
                ? `${moment(request.itChallanMonth).format('MM/YY')} ${request.itChallanStatus}`
                : ''}
            </span>
            {request.itChallan &&(            
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="ms-2 text-success cursor-pointer"
                    onClick={() => handleEditITStatus(request)} // Pass the request object
                    style={{ cursor: 'pointer', marginLeft: '8px' }}
                  />
            )}</td>    )}
                {selectedColumns.itfreq && <td>{request.itfreq}</td>}
               
                {selectedColumns.ITLastEdited && (
                <td>
    {request.lastITEditedBy && request.lastITDate
      ? `By ${request.lastITEditedBy} on ${moment(request.lastITDate).format('DD-MMM-YY')}`
      : 'Not Edited'}
  </td>
   )}
   {/* {selectedColumns.TracesLastEdited && (
  <td>
    {request.lastTracesEditedBy && request.lastTracesDate
      ? `By ${request.lastTracesEditedBy} on ${moment(request.lastTracesDate).format('DD-MMM-YY')}`
      : 'Not Edited'}
  </td>
    )} */}
    </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TaxCredentials;
