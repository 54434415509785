import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faEyeSlash, faEye, faSync, faEdit, faCirclePause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';
import { BrowserRouter as Router } from 'react-router-dom';

import ReactDOM from 'react-dom/client';
import MemoEditModal from './MemoEditModal';
import EditStatus from './EditStatusModal';
import EditChallanStatus from './editChallanStatusModal';
const Swal = require('sweetalert2');

library.add(faFilter, faEyeSlash, faEye, faSync, faEdit);

const GSTCredentials = () => {
  const [requests, setRequests] = useState([]);
  const [responsibleMembers, setResponsibleMembers] = useState({});
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [employeeData, setEmployeeData] = useState({});
  const [amount, setAmount] = useState('');
  const [memoData, setMemoData] = useState([]);
  const [memoCount, setMemoCount] = useState(0);
  const [ApplicableReturnCounts, setApplicableReturnCounts] = useState({});

  const [holdStatuses, setHoldStatuses] = useState({});
  const [editVisibility, setEditVisibility] = useState({}); 
  const [selectedColumns, setSelectedColumns] = useState({

    ResponsibleMember:true,
    TradeName: true,
    gstrf:true,
    GSTID: true,
    GSTPass: true,
    gstar1:true,
    gstar1EditedBy:true,
    gstChallanFreq:true,
    gstChallanStatus:true,
    gstar2:true,
    gstar2EditedBy:true,
    gstar3:true,
    gstar3EditedBy:true,
    hold:true,
    // gstar1Status:true
memo:true    

    });
  const [refresh, setRefresh] = useState(false);
  const [visibleRequests, setVisibleRequests] = useState({});

  const email = sessionStorage.getItem('email');
  const empid = employeeData.empid;

  const isMGEmployee =
  employeeData &&
  employeeData.empid &&
  typeof employeeData.empid === 'string' &&
  employeeData.empid.startsWith('MG');
const FullName = requests[0]?.FirstName +' '+ requests[0]?.LastName

useEffect(() => {
  const fetchEmployeeData = async () => {
    try {
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
        params: { email: email },
      });
      const employeeData = response.data[0];
      setEmployeeData(employeeData);
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };

  if (email) {
    fetchEmployeeData();
  }
}, [email]);



useEffect(() => {
  const fetchUserClientRequests = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/usermyclientdetails', { email });
      const requestsData = response.data;

      // Filter the requests where gstApplicable is 'yes'
      const filteredGSTRequests = requestsData.filter(request => request.gstApplicable === 'Yes');
      setRequests(filteredGSTRequests);
      setFilteredRequests(filteredGSTRequests);
    //  console.log(filteredGSTRequests,'fnn');
     

      // Fetch responsible members for each request's CC
      const memberRequests = filteredGSTRequests.map(request => 
        axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/fetchresponsibemember', { CC: request.CC })
      );

      const members = await Promise.all(memberRequests);
      const responsibleMembersData = members.reduce((acc, memberResponse, index) => {
        acc[filteredGSTRequests[index].CC] = memberResponse.data.responsibleMember;
        return acc;
      }, {});

      setResponsibleMembers(responsibleMembersData);

      // Fetch memo counts for each request's CC
      const countAdjMemo = filteredGSTRequests.map(request => 
        axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/countmemos?CC=${request.CC}`)
      );

     // Inside your fetchUserClientRequests function
const memoResponses = await Promise.all(countAdjMemo);

// Extract total counts and applicable return counts
const totalCounts = memoResponses.map(response => response.data.totalCount);
const applicableReturnCounts = memoResponses.map(response => response.data.applicableReturnCounts);

console.log(applicableReturnCounts  ,'spp');

// Set state for memo counts and applicable return counts
setMemoCount(totalCounts);
setApplicableReturnCounts(applicableReturnCounts); 


    } catch (error) {
      console.error('Error fetching client details or responsible members:', error);
      // Optionally set an error state here
    } finally {
      setLoading(false);
    }
  };

  fetchUserClientRequests();
}, [email, refresh]);


const toggleHoldState = async (clientId) => {
  console.log(clientId,'clientidd');
  
  const currentStatus = holdStatuses[clientId] || false;
    const newStatus = !currentStatus;

  
  try {
    // Update the hold status for the specific clientId
    await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateHoldStatus', { clientId, holdStatus: newStatus ? 1 : 0 });

    // Update the state and edit icon visibility
    setHoldStatuses((prevStatuses) => ({
      ...prevStatuses,
      [clientId]: newStatus,
    }));
    setEditVisibility((prevVisibility) => ({
      ...prevVisibility,
      [clientId]: holdStatuses,
    }));

    setRequests(prevRequests =>
      prevRequests.map(request =>
          request.CC === clientId
              ? { ...request, hold_status: request.hold_status === 1 ? 0 : 1 }
              : request
      )
  );
  } catch (error) {
    console.error("Error updating hold status:", error);
  }
};



useEffect(() => {
  setFilteredRequests(
    requests.filter((request) =>
      Object.values(request).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    )
  );
}, [searchQuery, requests]);

  const handleSelectAll = () => {
    const newSelectedColumns = Object.keys(selectedColumns).reduce((acc, column) => {
      acc[column] = true; // Set all columns to true
      return acc;
    }, {});
    setSelectedColumns(newSelectedColumns);
  };
  
  const handleSelectNone = () => {
    const newSelectedColumns = Object.keys(selectedColumns).reduce((acc, column) => {
      acc[column] = false; // Set all columns to false
      return acc;
    }, {});
    setSelectedColumns(newSelectedColumns);
  };
  
  const handleColumnChange = (e) => {
    const { name, checked } = e.target;
    setSelectedColumns(prevState => ({
      ...prevState,
      [name]: checked,
    }));
  };
  

  const toggleVisibility = (requestCC) => {
    setVisibleRequests((prevVisibleRequests) => ({
      ...prevVisibleRequests,
      [requestCC]: !prevVisibleRequests[requestCC],
    }));
  };

  const handleRefresh = () => {
    setRefresh((prevRefresh) => !prevRefresh);
  };
  const handleGST = () => {
    window.open('https://services.gst.gov.in/services/login', '_blank', 'noopener,noreferrer,width=1800,height=1800');

  };

  
const handleEditStatusModal = (request, statusKey, dateKey, editedKey, editedDate, statusType, statusTypeValue) => {
  Swal.fire({
      title: `Edit ${request.TradeName} ${statusKey === 'gstar1Status' ? request.gstar1 : request.gstar2} Status`,
      html: '<div id="edit-modal-root"></div>', 
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      width: '70%',
      didOpen: () => {
          const modalRoot = document.getElementById('edit-modal-root');
          const root = ReactDOM.createRoot(modalRoot);
          root.render(
                  <EditStatus
                      request={request}
                      statusKey={statusKey}
                      dateKey={dateKey}
                      editedKey={editedKey}
                      editedDate={editedDate}
                      statusType={statusType}
                      statusTypeValue={statusTypeValue}
                      handleRefresh={handleRefresh}
                  />
          );
      },
      showCloseButton: true,
  });
};


  
  // Function to show the edit modal with SweetAlert2
  const handleEdit = (request) => {
    Swal.fire({
      title: 'Edit GST Credentials',
      html: `
        <div class="mb-3">
          <label class="form-label float-start">Team Member</label>
          <input type="email" class="form-control" value="${request.empid[0]}" disabled>
        </div>
        <div class="mb-3">
          <label class="form-label float-start">GST ID</label>
          <input type="text" id="edit-gstid" class="form-control" value="${request.GSTID}" disabled>
        </div>
        <div class="mb-3">
          <label class="form-label float-start">Current GST Password</label>
          <input type="text" id="edit-gstpass" class="form-control" value="${request.GSTPass}" disabled>
        </div>
        <div class="mb-3">
          <label class="form-label float-start">Set New GST Password</label>
          <input type="text" id="new-gstpass" class="form-control" placeholder="Enter new password">
        </div>
      `,
      
      showCloseButton: true,
      confirmButtonText: 'Update',
      confirmButtonColor: '#198754', // Bootstrap 5 success color
      preConfirm: () => {
        const gstid = document.getElementById('edit-gstid').value;
        const currentGstpass = document.getElementById('edit-gstpass').value;
        const newGstpass = document.getElementById('new-gstpass').value;

        if (!gstid || !currentGstpass || !newGstpass) {
          Swal.showValidationMessage('All fields are required');
        }

        return { gstid, currentGstpass, newGstpass };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { gstid, currentGstpass, newGstpass } = result.value;

        // Make API call to update GSTID and GSTPass with the new password
        axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updategstcredentials', {
            email,
            empid:requests[0].empid[0],
            FullName:FullName,
            CC: request.CC,
            TradeName:request.TradeName,
            GSTID: gstid,
            currentGstpass:currentGstpass,
            GSTPass: newGstpass, // send the new GST password
          })
          .then(() => {
            Swal.fire({
              text: 'The credentials have been updated.',
              icon: 'success',
              toast: true,
              showConfirmButton: false, 
              position: 'top-end', // Positioning the toast
              timer: 1000, // Duration for the toast to appear
              timerProgressBar: true, // Show a progress bar
              willClose: () => {
                handleRefresh();
              }
            });
          })
          .catch((error) => {
            Swal.fire('Error!', 'Failed to update the credentials.', 'error');
            console.error('Error updating GST credentials:', error);
          });
      }
    });
  };



  const handleEditChallanStatus = (request,statusKey) => {
    Swal.fire({
      title: `Edit ${request.TradeName} GST Challan`,
      html: '<div id="edit-challan-status-modal-root"></div>',
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width:'60%',
      didOpen: () => {
        const modalRoot = document.getElementById('edit-challan-status-modal-root');
        const root = ReactDOM.createRoot(modalRoot);
        root.render(<EditChallanStatus request={request}  handleRefresh={handleRefresh}  statusKey={statusKey}/>);
      },
    });
  };


  
  
  const handleMemoEdit = (request) => {
    Swal.fire({
        title: 'Edit Memo Details',
        html: '<div id="modal-root"></div>', 
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        width: '80%',
       
        didOpen: () => {
            const modalRoot = document.getElementById('modal-root');
            const root = ReactDOM.createRoot(modalRoot);
            root.render(<MemoEditModal CC={request.CC} empid={request.empid} onClose={Swal.close} />);
        },
        showCloseButton: true, 
       
        
    });
};


  return (
    <div className="container mt-5" style={{ marginLeft: '1px', maxWidth: '2000px' }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>GST Compliance M & Q</h2>
        <div className="d-flex align-items-center">
          <button className="btn btn-secondary me-2" onClick={handleGST}>
             GST Login
          </button>
          <button className="btn btn-secondary me-2" onClick={handleRefresh}>
            <FontAwesomeIcon icon={faSync} /> Refresh
          </button>
         <div className="dropdown">
  <button
    className="btn btn-secondary dropdown-toggle"
    type="button"
    id="filterDropdown"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <FontAwesomeIcon icon={faFilter} /> Filter Columns
  </button>
  <ul className="dropdown-menu" aria-labelledby="filterDropdown">
    <li className="dropdown-item">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="selectAll"
          checked={Object.values(selectedColumns).every(Boolean)}
          onChange={handleSelectAll}
        />
        <label className="form-check-label" htmlFor="selectAll">
          Select All
        </label>
      </div>
    </li>
    <li className="dropdown-item">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="selectNone"
          checked={Object.values(selectedColumns).every(value => !value)}
          onChange={handleSelectNone}
        />
        <label className="form-check-label" htmlFor="selectNone">
          Select None
        </label>
      </div>
    </li>
    {Object.keys(selectedColumns).map((column) => (
      <li key={column} className="dropdown-item">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name={column}
            checked={selectedColumns[column]}
            onChange={handleColumnChange}
            id={`checkbox-${column}`}
          />
          <label className="form-check-label" htmlFor={`checkbox-${column}`}>
            {column}
          </label>
        </div>
      </li>
    ))}
  </ul>
  </div>
  </div>
</div>

      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border mt-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : filteredRequests.length === 0 ? (
        <p>No Clients found.</p>
      ) : (
        <table className="table table-striped">
          <thead style={{ fontSize: '15px', verticalAlign: 'baseline' }}>
            <tr>
            {isMGEmployee && (
              <>
            {selectedColumns.hold && <th>#</th>}
            </>
          )}
            {selectedColumns.ResponsibleMember && <th>Member <br />P L CC</th>}

              {selectedColumns.TradeName && <th>Trade Name <br />GSTIN</th>}

              {selectedColumns.gstrf && <th>RF</th>}

              {selectedColumns.GSTID && <th>ID</th>}


              {selectedColumns.GSTPass && <th>Pass</th>}
              <th>Edit</th>
              {selectedColumns.memo && <th>ADJ </th>}
              <th>Edit</th>
              {selectedColumns.gstar1 && <th>R1/IFF </th>}

              {selectedColumns.gstar1EditedBy && <th>Status</th>}
              
              <th>Edit</th>

              {selectedColumns.gstChallanFreq && <th>F</th>}
              
              {selectedColumns.gstChallanStatus && <th>Ch Pymt Status</th>}
              <th>Edit</th>
              {selectedColumns.gstar2 && <th>3B </th>}

              {selectedColumns.gstar2EditedBy && <th>Status </th>}
              <th>Edit</th>

            </tr>
          </thead>
          <tbody style={{ fontSize: '15px', verticalAlign: 'baseline' }}>
  {filteredRequests.map((request, index) => {
    // Get responsible member by CC
    const responsibleMember = responsibleMembers[request.CC];

    return (
      <tr key={index}>
        {/* Render Responsible Member */}
        {isMGEmployee && (
        <td>  
        <button
          onClick={() => toggleHoldState(request.CC)} 
          className="btn"
        >
           {request.hold_status === 1  ? <FontAwesomeIcon icon={faPlay} /> : <FontAwesomeIcon icon={faCirclePause} />}
        </button>
      </td>
      )}
        {selectedColumns.ResponsibleMember && (
  <td>
    {responsibleMember ? (
      <>
        {responsibleMember.firstname}
        <br />
        {request.Priority} {request.levelOfBOA} {request.CC}
      </>
    ) : (
      'N/A'
    )}
  </td>
)}


        {selectedColumns.TradeName && <td>{request.TradeName} <br />{request.GSTIN}</td>}

        {selectedColumns.gstrf && <td>{request.gstrf}</td>}

        {selectedColumns.GSTID && (
          <td>
            {visibleRequests[request.CC] ? (
              request.GSTID
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} onClick={() => toggleVisibility(request.CC)} />
            )}
          </td>
        )}

        {selectedColumns.GSTPass && (
          <td>
            {visibleRequests[request.CC] ? (
              <>
                {request.GSTPass}
              
                  <br />{selectedColumns.LastEdited && (
          
          <td>
            {request.lastGstEditedBy && request.lastGstDate
              ? `${request.lastGstEditedBy}/${moment(request.lastGstDate).format('DD-MMM')}`
              : '-'}
          </td>
        )}

               
              </>
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} onClick={() => toggleVisibility(request.CC)} />
            )} 
          </td> 
        )}

        
        <td> <FontAwesomeIcon icon={faEdit}  onClick={() => handleEdit(request)} className="ms-2 text-success cursor-pointer"/></td>

        <td>
          {/* Use the index to access the memo count */}
          <span style={{
        fontWeight: '600',
        color: memoCount[index] === 0 ? '#006400' : 'red', // Green if count is 0, red otherwise
      }}
>
          {memoCount[index] !== undefined ? memoCount[index] : 0}
          </span>
        
        </td>
<td>  <FontAwesomeIcon
            icon={faEdit}
            onClick={() => handleMemoEdit(request)}
            className="ms-2 text-success cursor-pointer"
          /></td>

        {selectedColumns.gstar1 && <td>{request.gstar1}</td>}


        {selectedColumns.gstar1EditedBy && (
  <td
  style={{
    fontWeight: '600',
    color:
      request.gstar1Status === 'To be Filed'
        ? 'red' // Set color to red if status is "To be Filed"
        : request.gstar1Status === 'Filled' || request.gstar1Status === 'Filed - WRT'
        ? moment(request.gstar1Date).isBefore(moment().subtract(1, 'month'), 'month')
          ? 'blue' // Set color to blue if gstar1Date is older than 1 month
          : '#006400' // Set color to green if within one month
        : '#F9A602', // Default color
  }}
>
    {request.hold_status !== 1  ? <>
    <span>
      {request.gstar1Status && request.gstar1Date
        ?`${moment(request.gstar1Date).format('MM/YY')} ${request.gstar1Status}`
        : '-'}
    </span>

   

    <br />

    {request.gstar1EditedBy && request.gstar1EditedByDate
      ? `${request.gstar1EditedBy}
      `
      : '-'}
      </> : <span style={{color:'red'}}>Hold for Non-Payment</span>}
  </td>
)}




  <td> 
  {request.hold_status !== 1 && (
    <>
    {request.gstar1 && (
      <FontAwesomeIcon
        icon={faEdit}
        className="ms-2 text-success cursor-pointer"
        onClick={() =>
          handleEditStatusModal(
            request,
            'gstar1Status',
            'gstar1Date',
            'gstar1EditedBy',
            'gstar1EditedByDate',
            'gstar1',
            `${request.gstar1}`
          )
        }
      />
    )}
  </>
  )}
    </td>
        <td>{selectedColumns.gstChallanFreq && <td>{request.gstChallanFrequency}</td>}</td>
        {/* <td> {selectedColumns.gstChallan && <td>{request.gstChallan}</td>}</td> */}




        {selectedColumns.gstChallanStatus && 
                (
                <td style={{
                  fontWeight:'600',
                  color:
                    request.gstChallanStatus === 'Paid'  || request.gstChallanStatus === 'Paid by CA/TP' || request.gstChallanStatus === 'Not Required'? moment(request.gstChallanMonth).isBefore(moment().subtract(1, 'month'), 'month')
                    ? 'blue'  // Change color to blue if gstar1Date is older than 1 month
                    : '#006400'  // Green if gstar1Status is Filled or To be Filed, but within one month
                      : 'red', // Default color if none of the statuses match
                }}>
                  {request.hold_status !== 1  ? <>
                <span>
                {request.gstChallanStatus && request.gstChallanStatus ? (
  <>
    {moment(request.gstChallanMonth).format('MM/YY')} {request.gstChallanStatus}
  </>
) : ''}
            </span>
             <br />
              {request.gstChallanEditedBy && request.gstChallanEditedByDate
              ? `${request.gstChallanEditedBy}`
              : '-'}
              </>: <span style={{color:'red'}}>Hold for Non-Payment</span>}
            </td>    )}
        

            <td>
            {request.hold_status !== 1  && (
              <>
              {request.gstChallan &&(            
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="ms-2 text-success cursor-pointer"
                    onClick={() => handleEditChallanStatus(request,'gstChallanStatus')} // Pass the request object
                    style={{ cursor: 'pointer', marginLeft: '8px' }}
                  />
            )}
            </>
            )}
            </td>


        {selectedColumns.gstar2 && <td>{request.gstar2} 
        
        </td>}
        {selectedColumns.gstar2EditedBy && (
         <td
         style={{
           fontWeight: '600',
           color:
             request.gstar2Status === 'To be Filed'
               ? 'red' // Set color to red if status is "To be Filed"
               : request.gstar2Status === 'Filled' || request.gstar2Status === 'Filed - WRT'
               ? moment(request.gstar2Date).isBefore(moment().subtract(1, 'month'), 'month')
                 ? 'blue' // Set color to blue if gstar2Date is older than 1 month
                 : '#006400' // Set color to green if within one month
               : '#F9A602', // Default color
         }}
       >
            {request.hold_status !== 1  ? <>
              <span>
                
              {request.gstar2Status && request.gstar2Status
                ? `${moment(request.gstar2Date).format('MM/YY')} ${request.gstar2Status}`
                : ''}
            </span>
            <br />
             {request.gstar2EditedBy && request.gstar2EditedByDate
              ? `${request.gstar2EditedBy}`
              : '-'}
        </> : <span style={{color:'red'}}> Hold for Non-Payment</span>}
          </td>
        )}
         <td>{request.hold_status !== 1  && (
          <>
           {request.gstar2 && (
             
             <FontAwesomeIcon icon={faEdit} className="ms-2 text-success cursor-pointer" onClick={() => handleEditStatusModal(request, 'gstar2Status', 'gstar2Date', 'gstar2EditedBy','gstar2EditedByDate','gstar2',
              `${request.gstar2}`)}/>
          
         )}
         </>
         )}
         </td>
      </tr>
    );
  })}
</tbody>

        </table>
      )}
    </div>
  );
};

export default GSTCredentials;
