import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';

const EditStatus = ({
    request,
    statusKey,
    dateKey,
    editedKey,
    editedDate,
    statusType,
    statusTypeValue,
    handleRefresh
}) => {
    const [statusOptions, setStatusOptions] = useState([]);
    const [date, setDate] = useState('');
    const [newStatus, setNewStatus] = useState('');
    const [suppliesData, setSuppliesData] = useState({});
    const [currentMonth] = useState(moment());

    
    

    useEffect(() => {
        const lastEditedBy = request[editedKey] || 'N/A';
        const lastEditedDate = request[editedDate] ? moment(request[editedDate]).format('DD-MMM-YY') : 'N/A';
        const fetchdate = request[dateKey] ? moment(request[dateKey]).format('YYYY-MM') : 'N/A';
        setDate(fetchdate);

        const optionsArray =
            statusKey === 'gstar1Status'
                ? ["Data not recd", "Filled", "Filed - WRT", "Submitted", "Submitted to CA/TP", "Suspended", "To be Filed", "Uploaded", "Verified"]
                : ["Challan Unpaid", "Data not recd", "Filled", "Filed - WRT", "Submitted", "Submitted to CA/TP", "Suspended", "To be Filed", "Uploaded", "Verified"];

        setStatusOptions(optionsArray);
    }, [request, statusKey, dateKey, editedKey, editedDate]);

    const calculateTotal = () => {
        return Object.values(suppliesData).reduce((total, { igst, cgst, sgst, cess }) => {
            return total + (igst || 0) + (cgst || 0) + (sgst || 0) + (cess || 0);
        }, 0);
    };

    const handleInputChange = (supplyType, field, value) => {
        setSuppliesData(prevState => ({
            ...prevState,
            [supplyType]: {
                ...prevState[supplyType],
                [field]: parseFloat(value) || 0
            }
        }));
    };

    const handleConfirm = async () => {
        const selectedMonth = moment(date);
        const lastFilledMonth = moment(request[dateKey]);
        const dbstatusKey = request[statusKey] || '';

        if (dbstatusKey === 'Filled' && selectedMonth.isBefore(lastFilledMonth)) {
            Swal.showValidationMessage('You cannot select an earlier month than the last "Filled" month.');
            return;
        }

        if (selectedMonth.isSame(currentMonth, 'month') && selectedMonth.isSame(currentMonth, 'year') || selectedMonth.isAfter(currentMonth)) {
            Swal.showValidationMessage('You cannot file a return for the current or future months as they are not yet completed.');
            return;
        }

        if ((statusKey === 'gstar2Status' || statusKey === 'gstar3Status') && request.gstar1Status !== 'Filled') {
            Swal.showValidationMessage(`Please fill R1 for the selected month first.`);
            return false;
        }

        const supplies = Object.entries(suppliesData).reduce((acc, [supplyType, data]) => {
            acc[supplyType] = {
                tv: data.tv || 0,
                igst: data.igst || 0,
                cgst: data.cgst || 0,
                sgst: data.sgst || 0,
                cess: data.cess || 0,
            };
            return acc;
        }, {});

        try {
            await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/gstarupdateStatus', {
                email: request.email,
                empid: request.empid[0],
                FullName: request.FullName,
                CC: request.CC,
                statusType: statusTypeValue,
                statusKey,
                dateKey,
                editedKey,
                newStatus,
                date,
                editedDate,
                suppliesData: supplies
            });
            Swal.fire({
                text: 'The status has been updated.',
                icon: 'success',
                toast: true,
                showConfirmButton: false,
                position: 'top-end',
                timer: 1000,
                timerProgressBar: true,
                willClose: handleRefresh
            });
        } catch (error) {
            Swal.fire('Error!', 'Failed to update the status.', 'error');
            console.error('Error updating status:', error);
        }
    };


    const supplyTypes = statusKey === 'gstar2Status'
        ? ['Table 3.1', 'FCM-O/S', 'ZRS-O/S', 'Nil/EXEMP-O/S', 'RCM-I/S', 'Non GST-O/S', 'Table 4', 'Import', 'RCM', 'All other ITC', 'Others']
        : ['FCM-O/S', 'RCM-O/S', 'ZRS-O/S', 'Nil/EXEMP-O/S', 'Non GST-O/S'];

    return ( 
        <div className="container">
            <div className="row mb-3">
                <div className="col-md-6">
                    <label className="form-label float-start">Team Member</label>
                    <input type="text" className="form-control" value={request.empid[0]} disabled />
                </div>
                <div className="col-md-6">
                    <label className="form-label float-start">Date</label>
                    <input type="month" className="form-control" value={date} onChange={e => setDate(e.target.value)} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    <label className="form-label float-start">Status</label>
                    <select className="form-select" onChange={e => setNewStatus(e.target.value)}>
                        <option value="">Select Option</option>
                        {statusOptions.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-6">
                    <label className="form-label float-start">Last Edited</label>
                    <input type="text" className="form-control" value={`${request[editedKey] || 'N/A'}/${moment(request[editedDate]).format('DD-MMM-YY') || 'N/A'}`} disabled />
                </div>
            </div>
            <div id="additional-fields" style={{ display: newStatus === 'Filled' || newStatus === 'Submitted to CA/TP' ? 'block' : 'none' }}>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Nature </th>
                            <th>TV (₹)</th>
                            <th>IGST (₹)</th>
                            <th>CGST(₹)</th>
                            <th>S/UT GST (₹)</th>
                            <th>CESS (₹)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {supplyTypes.map(supplyType => (
                            (supplyType === 'Table 3.1' || supplyType === 'Table 4') ? (
                                <tr key={supplyType}>
                                    <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'center' }}>{supplyType}</td>
                                </tr>
                            ) : (
                                <tr key={supplyType}>
                                    <td style={{width:'180px', textAlign:'left'}}>{supplyType}</td>
                                    <td><input type="number" className="form-control" onChange={e => handleInputChange(supplyType, 'tv', e.target.value)} /></td>
                                    <td><input type="number" className="form-control" onChange={e => handleInputChange(supplyType, 'igst', e.target.value)} disabled={supplyType === 'Nil/EXEMP-O/S' || supplyType === 'Non GST-O/S'} /></td>
                                    <td><input type="number" className="form-control" onChange={e => handleInputChange(supplyType, 'cgst', e.target.value)} disabled={supplyType === 'ZRS-O/S' || supplyType === 'Nil/EXEMP-O/S' || supplyType === 'Non GST-O/S'} /></td>
                                    <td><input type="number" className="form-control" onChange={e => handleInputChange(supplyType, 'sgst', e.target.value)} disabled={supplyType === 'ZRS-O/S' || supplyType === 'Nil/EXEMP-O/S' || supplyType === 'Non GST-O/S'} /></td>
                                    <td><input type="number" className="form-control" onChange={e => handleInputChange(supplyType, 'cess', e.target.value)} disabled={supplyType === 'Nil/EXEMP-O/S' || supplyType === 'Non GST-O/S'} /></td>
                                </tr>
                            )
                        ))}
                    </tbody>
                </table>
            </div>
            <button className="btn btn-success" onClick={handleConfirm}>Update</button>

        </div>
    );
};

export default EditStatus;
