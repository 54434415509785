import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faEye, faSync, faEdit } from '@fortawesome/free-solid-svg-icons'; 
import { library } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';
import ReactDOM from 'react-dom/client';
import TransporterDetailsModal from './TransporterDetailsModal';
const Swal = require('sweetalert2');

library.add(faFilter, faEye, faSync, faEdit);

const EWayInvoiceCredentials = () => {
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [responsibleMembers, setResponsibleMembers] = useState({});
  const [loading, setLoading] = useState(true);
  const [visibleRequests, setVisibleRequests] = useState({});
  const [selectedColumns, setSelectedColumns] = useState({
    CC: true,
    TradeName: true,
    GSTIN: true,
    EwayID: true,
    EwayPass: true,
    IRNID: true,
    IRNPass: true,
    ewayLastEdited:false,
    irnLastEdited:false,
    levelOfBOA:true,
    reportingFrequency:true,
    gstar:true,
    gstrf:true,
    Priority:true,
    ResponsibleMember:true,
    transporterDetails:true
  });
  const [refresh, setRefresh] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [visibleFields, setVisibleFields] = useState({});
  

  const email = sessionStorage.getItem('email');
  const FullName = requests[0]?.FirstName +' '+ requests[0]?.LastName
  useEffect(() => {
    const fetchUserClientRequests = async () => {
      setLoading(true);
      try {
        const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/usermyclientdetails', { email });
        const requestsData = response.data;
        setRequests(requestsData);
        setFilteredRequests(requestsData);
        
        // Fetch responsible members for each request's CC
        const memberRequests = requestsData.map(request => {
          return axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/fetchresponsibemember', { CC: request.CC });
        });
  
        const members = await Promise.all(memberRequests);
        const responsibleMembersData = members.reduce((acc, memberResponse, index) => {
          acc[requestsData[index].CC] = memberResponse.data.responsibleMember;
          return acc;
        }, {});
  
        setResponsibleMembers(responsibleMembersData);
      } catch (error) {
        console.error('Error fetching client details or responsible members:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchUserClientRequests();
  }, [email, refresh]);

  useEffect(() => {
    const filtered = requests.filter((request) =>
      Object.values(request).some(
        (value) =>
          value !== null &&
          value !== undefined &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredRequests(filtered);
  }, [searchTerm, requests]);

  const handleColumnChange = (e) => {
    setSelectedColumns({
      ...selectedColumns,
      [e.target.name]: e.target.checked,
    });
  };

  const handleRefresh = () => {
    setRefresh(prevRefresh => !prevRefresh);
  };
  const EwayBill = () => {
    window.open('https://ewaybillgst.gov.in/login.aspx', '_blank', 'noopener,noreferrer,width=1800,height=1800');
    };
    const einvoice = () => {
      window.open('https://einvoice1.gst.gov.in/', '_blank', 'noopener,noreferrer,width=1800,height=1800');
      };
  // const toggleVisibility = (index, field) => {
  //   setVisibleFields((prev) => ({
  //     ...prev,
  //     [field]: !prev[field],
  //   }));
  // };

  
  const toggleVisibility = (requestCC) => {
    setVisibleRequests((prevVisibleRequests) => ({
      ...prevVisibleRequests,
      [requestCC]: !prevVisibleRequests[requestCC],
    }));
  };
  const handleSelectAll = () => {
    const newSelectedColumns = Object.keys(selectedColumns).reduce((acc, column) => {
      acc[column] = true; // Set all columns to true
      return acc;
    }, {});
    setSelectedColumns(newSelectedColumns);
  };
  
  const handleSelectNone = () => {
    const newSelectedColumns = Object.keys(selectedColumns).reduce((acc, column) => {
      acc[column] = false; // Set all columns to false
      return acc;
    }, {});
    setSelectedColumns(newSelectedColumns);
  };

  const showTransporterDetails = (CC) => {
    Swal.fire({
      title: 'Transporter Details',
      html: `<div id="transporter-modal"></div>`, // Placeholder for the modal content
      showCloseButton: false,
      width:"80%",
    didOpen: () => {
        // Render the TransporterDetailsModal component inside the modal
        const modalContainer = document.getElementById('transporter-modal');
        const root = ReactDOM.createRoot(modalContainer);
        root.render(<TransporterDetailsModal CC={CC} />);
      },
    });
  };


  const handleEdit = (request, fieldType) => {
    const fieldID = `${fieldType}ID`; // Dynamically setting field ID (EwayID or IRNID)
    const fieldPass = `${fieldType}Pass`; // Dynamically setting field Password (EwayPass or IRNPass)

    Swal.fire({
      title: `Edit ${fieldType} Credentials`,
      html: `
        <div class="mb-3">
          <label class="form-label float-start">Team Member</label>
          <input type="text" class="form-control" value="${request.empid[0]}" disabled>
        </div>
        <div class="mb-3">
          <label class="form-label float-start">${fieldType} ID</label>
          <input type="text" id="edit-id" class="form-control" value="${request[fieldID]}" disabled >
        </div>
        <div class="mb-3">
          <label class="form-label float-start">Current ${fieldType} Password</label>
          <input type="text" id="edit-password" class="form-control" value="${request[fieldPass]}" disabled>
        </div>
        <div class="mb-3">
          <label class="form-label float-start">Set New ${fieldType} Password</label>
          <input type="text" id="new-password" class="form-control" placeholder="Enter new password">
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: 'Update',
      confirmButtonColor: '#198754',
      preConfirm: () => {
        const id = document.getElementById('edit-id').value;
        const currentPassword = document.getElementById('edit-password').value;
        const newPassword = document.getElementById('new-password').value;

        if (!newPassword) {
          Swal.showValidationMessage('All fields are required');
        }

        return { id, currentPassword, newPassword };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { id, currentPassword,newPassword } = result.value;

        // Make API call to update ID and Password
        axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateewayinvoicecredentials', {
            email,
            empid: requests[0].empid[0],
            FullName:FullName,
            CC: request.CC,
            type: fieldType,
            TradeName:request.TradeName,
            credentialID:id,
            oldPassword:currentPassword,
            newPassword: newPassword,
          })
          .then(() => {
            Swal.fire({
              toast:true,
              text: `${fieldType} credentials have been updated.`,
              icon: 'success',
              toast: true,
              showConfirmButton: false, 
              position: 'top-end', // Positioning the toast
              timer: 1000, // Duration for the toast to appear
              timerProgressBar: true, // Show a progress bar
              willClose: () => {
                handleRefresh();
              }
            });
          })
          .catch((error) => {
            Swal.fire('Error!', 'Failed to update the credentials.', 'error');
            console.error('Error updating credentials:', error);
          });
      }
    });
  };

  return (
    <div className="container mt-5" style={{ marginLeft: '1px', maxWidth: '2000px' }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>E-Way Compliances</h2>
        <div className="d-flex align-items-center">
        <button className="btn btn-secondary me-2" onClick={EwayBill}>
             Eway Login
          </button>
          {/* <button className="btn btn-secondary me-2" onClick={einvoice}>
             E-invoice Login
          </button> */}
          {/* <button className="btn btn-secondary me-2" onClick={handleRefresh}>
            <FontAwesomeIcon icon={faSync} /> Refresh
          </button> */}

          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="filterDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <FontAwesomeIcon icon={faFilter} /> Filter Columns
            </button>
            <ul className="dropdown-menu" aria-labelledby="filterDropdown">
    <li className="dropdown-item">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="selectAll"
          checked={Object.values(selectedColumns).every(Boolean)}
          onChange={handleSelectAll}
        />
        <label className="form-check-label" htmlFor="selectAll">
          Select All
        </label>
      </div>
    </li>
    <li className="dropdown-item">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="selectNone"
          checked={Object.values(selectedColumns).every(value => !value)}
          onChange={handleSelectNone}
        />
        <label className="form-check-label" htmlFor="selectNone">
          Select None
        </label>
      </div>
    </li>
              {Object.keys(selectedColumns).map((column) => (
                <li key={column} className="dropdown-item">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name={column}
                      checked={selectedColumns[column]}
                      onChange={handleColumnChange}
                      id={`checkbox-${column}`}
                    />
                    <label className="form-check-label" htmlFor={`checkbox-${column}`}>
                      {column}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border mt-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : filteredRequests.length === 0 ? (
        <p>No Clients found.</p>
      ) : (
        <table className="table table-striped">
          <thead style={{ fontSize: '15px', verticalAlign: 'baseline' }}>
            <tr>
            {selectedColumns.ResponsibleMember && <th>Member <br />P L CC</th>}
            {/* {selectedColumns.Priority && <th>P</th>} */}
              {/* {selectedColumns.levelOfBOA && <th>L</th>} */}
              {/* {selectedColumns.CC && <th>CC</th>} */}
              {selectedColumns.TradeName && <th>Trade Name <br />GSTIN</th>}
              {/* {selectedColumns.reportingFrequency && <th>R</th>} */}
              {/* {selectedColumns.gstar && <th>AR</th>} */}
              {/* {selectedColumns.GSTIN && <th>GSTIN</th>} */}
              {selectedColumns.gstrf && <th>RF</th>}
              {selectedColumns.EwayID && <th>EWAY ID</th>}
              {selectedColumns.EwayPass && <th>EWAY Pass</th>}
              {/* {selectedColumns.IRNID && <th>IRN ID</th>}
              {selectedColumns.IRNPass && <th>IRN Pass</th>} */}
              {selectedColumns.ewayLastEdited && <th>Eway Last Edited</th>}
              {/* {selectedColumns.irnLastEdited && <th>IRN Last Edited</th>} */}
              {selectedColumns.transporterDetails && <th>transporterDetails</th>}
            </tr>
          </thead>
          <tbody style={{ fontSize: '14px', verticalAlign: 'baseline' }}>
            {filteredRequests.map((request, index) => {
               const responsibleMember = responsibleMembers[request.CC];
               return (
              <tr key={index}>
               {selectedColumns.ResponsibleMember && (
  <td>
    {responsibleMember ? (
      <>
        {responsibleMember.firstname}
        <br />
        {request.Priority} {request.levelOfBOA} {request.CC}
      </>
    ) : (
      'N/A'
    )}
  </td>
)}
                   

                {selectedColumns.TradeName && <td>{request.TradeName} <br />{request.GSTIN}</td>}
        
                {selectedColumns.gstrf && <td>{request.gstrf}</td>}

                {selectedColumns.EwayID && (
                  <td>
                    {visibleRequests[request.CC] ? (
                      <span>{request.EwayID}</span>
                    ) : (
                      <span>
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => toggleVisibility(request.CC)}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    )}
                  </td>
                )}

                {selectedColumns.EwayPass && (
                  <td>
                    {visibleRequests[request.CC] ? (
              request.GSTID
            ) : (
                      <span>
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => toggleVisibility(request.CC)}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    )}
                    {visibleRequests[request.CC] && (
                     
                        <FontAwesomeIcon className='ms-2 text-success cursor-pointer' icon={faEdit} onClick={() => handleEdit(request, 'Eway')} style={{ cursor: 'pointer' }}/>
                     
                    )}
                  </td>
                )}

                {/* {selectedColumns.IRNID && (
                  <td>
                    {visibleRequests[request.CC] ? (
                      <span>{request.IRNID}</span>
                    ) : (
                      <span>
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => toggleVisibility(request.CC)}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    )}
                  </td>
                )}

                {selectedColumns.IRNPass && (
                  <td>
                    {visibleRequests[request.CC] ? (
                      <span>{request.IRNPass}</span>
                    ) : (
                      <span>
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => toggleVisibility(request.CC)}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    )}
                    {visibleRequests[request.CC] && (
                     
                        <FontAwesomeIcon className='ms-2 text-success cursor-pointer' icon={faEdit} onClick={() => handleEdit(request, 'IRN')} style={{ cursor: 'pointer' }}/>
                      
                    )}
                  </td>
                )} */}
                {selectedColumns.ewayLastEdited && (
                <td>
    {request.lastEwayEditedBy && request.lastEwayDate
      ? `By ${request.lastEwayEditedBy} on ${moment(request.lastEwayDate).format('DD-MMM-YY')}`
      : 'Not Edited'}
  </td>
)}
  
  {
  
  /* {selectedColumns.irnLastEdited && (
  <td>
    {request.lastIRNEditedBy && request.lastIRNDate
      ? `By ${request.lastIRNEditedBy} on ${moment(request.lastIRNDate).format('DD-MMM-YY')}`
      : 'Not Edited'}
  </td>
  )} */
 
  }

     <td>
      {request.transporterDetails}
      <FontAwesomeIcon 
        icon="fa-solid fa-eye" 
        onClick={() => showTransporterDetails(request.CC)} 
        style={{ cursor: 'pointer', marginLeft: '5px' }} 
      />
    </td>
  
              </tr>
    );
  })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default EWayInvoiceCredentials;
